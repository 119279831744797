<template>
  <div class="app">
    <el-dialog
        title="选择省份城市"
        :visible.sync="dialogSendVisible"
        width="30%"
        :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item
            label="选择省份"
            prop="province"
            :rules="[{ required: true, message: '请选择城市', trigger: 'blur' }]"
        >
          <el-select
              v-model="form.province"
              @change="setArea"
              filterable
              placeholder="请选择省份"
              style="width: 100%"
          >
            <el-option
                v-for="rs in cityAreaData"
                :key="rs.code"
                :label="rs.name"
                :value="rs.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="选择城市"
            prop="city"
            :rules="[{ required: true, message: '请选择城市', trigger: 'blur' }]"
        >
          <el-select
              v-model="form.city"
              filterable
              @change="setCity"
              placeholder="请选择城市"
              style="width: 100%"
          >
            <el-option
                v-for="rs in cityAreaData2"
                :key="rs.code"
                :label="rs.name"
                :value="rs.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      formCancel: "",
      cityAreaData: "",
      cityAreaData2: "",
      arrayArea : {},
      arrayArea2:{},
      form: {
        province: "",
        city: "",
        code: "",
        provinceTxt: "",
        cityTxt: "",
        codeTxt: "",
      },
      dialogSendVisible: true,
    };
  },
  methods: {
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("get-operation-area", this.form);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("get-operation-area", false);

    },
    setArea(val) {
      this.form.provinceTxt = this.arrayArea[val];
      console.log(val,this.arrayArea[val])
      this.getCityArea(val);
    },
    setCity(val) {
      console.log(val)
      this.form.code = val.code;
      this.form.codeTxt = this.arrayArea2[val];
    },
    getCityArea(val) {
      this.$postFormData(this.$api.cityArea, { parentCode: val }).then(
          (res) => {
            if (res.code == 1) {
              if (val === 1) {
                this.cityAreaData = res.data;
                this.cityAreaData.forEach((rs)=>{
                  this.arrayArea[rs.code] = rs.name;
                })
              } else {
                this.cityAreaData2 = res.data;
                this.cityAreaData2.forEach((rs)=>{
                  this.arrayArea2[rs.code] = rs.name;
                })
              }
            }
          }
      );
    },
  },
  created() {
    this.getCityArea(1);
  },
};
</script>
