<template>
  <div class="app">
    <el-dialog
        title="请选择"
        :visible.sync="dialogSendVisible"
        width="30%"
    >
      <el-cascader
          ref="cascaderAddr"
          v-model="sendAddress"
          placeholder="请选择"
          :options="provenceArray"
          @change="selected"
          :props="props"
      ></el-cascader>
    </el-dialog>
  </div>
</template>
<script>
let list = [];

export default {
  data() {
    let _self = this;

    return {
      sendAddress: "",
      provenceArray: [],
      props: {
        lazy: true,
        // checkStrictly:true,
        lazyLoad: _self.cascaderLazyload,
      },
      where: {
        type: 1,
        isOpen: 1,
        areaCode: 1,
      },
      dialogSendVisible: true,
    };
  },
  methods: {
    cascaderLazyload(node, resolve) {
      this.where.type = node.level + 1;
      this.where.isOpen = 1;
      this.where.areaCode = node.value;
      this.$postFormData(this.$api.openAreaList, this.where).then((res) => {
        if (res.code == 1) {
          let cities = (res && res.data && res.data) || [];
          let children = [];
          cities.forEach((item) => {
            const rs = {
              value: item.code,
              label: item.name,
              children: [],
              leaf: node.level >= 2,
            };
            children.push(rs);
            list[item.code] = item.name;
          });
          resolve(children);
        }
      });
    },
    getProvence() {
      this.provenceArray = [];
      this.$postFormData(this.$api.openAreaList, this.where).then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            const rs = {
              value: item.code,
              label: item.name,
              children: [],
            };
            this.provenceArray.push(rs);
            list[item.code] = item.name;
          });
        } else {
          this.$message({
            type: "warning",
            message: "区域不完善",
          });
        }
      });
    },
    selected(val) {
      if (val && Array.isArray(val) && val.length === 3) {
        let array = [];
        val.forEach((item, index) => {
          array[index] = list[item];
        });
        this.$emit("get-operation-area", { code: val, name: array });
      }
    },
  },
};
</script>
