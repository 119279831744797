<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>门店审核列表</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form ref="form" :model="infoDetail" label-width="80px">

      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">商家信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">商户类型</div>
            <el-select v-model="infoDetail.merchantType" placeholder="请选择">
              <el-option
                  v-for="(item,index) in merchantType"
                  :key="index"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">ID</div>
            <el-input
                placeholder=""
                v-model="infoDetail.merchantId"
                disabled
            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">公司名称</div>
            <el-input
                placeholder=""
                v-model="infoDetail.companyName"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">手机号码（登录账号）</div>
            <el-input
                placeholder=""
                v-model="infoDetail.phoneNumber"

            ></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <div class="sub-title">法人姓名</div>
            <el-input
                placeholder=""
                v-model="infoDetail.legaler"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">法人身份证号</div>
            <el-input
                placeholder=""
                v-model="infoDetail.legalerIdCardNo"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">统一社会信用代码</div>
            <el-input
                placeholder=""
                v-model="infoDetail.businessLicenseCode"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">执照有效期</div>
            <el-input
                placeholder=""
                v-model="infoDetail.effectiveDateEnd"

            ></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <div class="sub-title">申请时间</div>
            <el-input
                placeholder=""
                v-model="infoDetail.applyDate"

            ></el-input>
          </el-col>
          <el-col :span="6"></el-col>
          <el-col :span="6"></el-col>
          <el-col :span="6"></el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">结算信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">开户银行</div>
            <el-input
                placeholder=""
                v-model="infoDetail.bankName"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">银行卡号</div>
            <el-input
                placeholder=""
                v-model="infoDetail.bankNo"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">开户名称</div>
            <el-input
                placeholder=""
                v-model="infoDetail.bankUserName"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">帐户类型</div>
            <el-select v-model="infoDetail.accountType" placeholder="请选择">
              <el-option label="对公" value="00"></el-option>
              <el-option label="对私" value="01"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <div class="sub-title" @click="areaShow = true">开户省份 <font color="blue">+选择</font></div>
            <el-input
                placeholder=""
                v-model="infoDetail.bankOpenArea"

            ></el-input>
          </el-col>

          <el-col :span="6">
            <div class="sub-title">开户支行</div>
            <el-input
                placeholder=""
                v-model="infoDetail.bankAddress"

            ></el-input>
          </el-col>

          <el-col :span="6">
            <div class="sub-title">结算类型</div>
            <el-select v-model="infoDetail.settlePersonType" placeholder="请选择">
              <el-option label="法人" value="0"></el-option>
              <el-option label="非法人" value="1"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <div class="sub-title">联行号</div>
            <el-input
                placeholder=""
                v-model="infoDetail.branchNo"
                disabled
            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">预留手机号</div>
            <el-input
                placeholder=""
                v-model="infoDetail.phoneNumber"

            ></el-input>
          </el-col>
          <el-col :span="6"></el-col>
          <el-col :span="6"></el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">门店信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">门店ID</div>
            <el-input
                placeholder=""
                v-model="infoDetail.id"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">门店名称</div>
            <el-input
                placeholder=""
                v-model="infoDetail.shopName"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">经营类型</div>
            <el-select
                v-model="infoDetail.categoryCode"
                filterable
                @change="getCategory"
                placeholder="请选择省份"
            >
              <el-option
                  v-for="rs in typeList"
                  :key="rs.id"
                  :label="rs.categoryName"
                  :value="rs.categoryCode"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <div class="sub-title" @click="operationShow = true">所属区域 <font color="blue">+选择</font></div>
            <el-input
                placeholder=""
                v-model="infoDetail.shopArea"

            ></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <div class="sub-title">赠豆比例</div>
            <el-input
                placeholder=""
                v-model="infoDetail.givingPercent"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">收款码ID</div>
            <el-input
                placeholder=""
                v-model="infoDetail.payCardNo"

            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">加入时间</div>
            <el-input
                placeholder=""
                v-model="infoDetail.applyDate"

            ></el-input>
          </el-col>
          <el-col :span="6"></el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">证件信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="span" v-for="(rs,index) in infoDetail.merchantImages" :key="index">
            <div class="images">
              <div class="sub-title">
                {{ rs.imageTypeText }}
                <!--              <el-button v-if="rs.imagePath" :data-img="[rs.imagePath]" type="text" size="small" @click="this.$imgPreview()">查看图片</el-button>-->
              </div>
              <upload
                  v-if="showPic"
                  @getUploadFile="getUploadFile"
                  title=""
                  :field="rs.imageType"
                  :param="upload.office"
                  :urlPath="rs.imagePath ? rs.imagePath : ''"
              ></upload>
            </div>
          </el-col>

        </el-row>
      </div>

      <div class="admin_main_block admin_m15">
        <el-row>
          <el-col>
            <el-button icon="el-icon-check" type="primary" @click="success">
              审核
            </el-button>
            <el-button icon="el-icon-edit" type="success" @click="update">
              修改
            </el-button>
            <el-button icon="el-icon-back" type="warning" @click="back">
              审核不通过
            </el-button>
          </el-col>
        </el-row>
      </div>
      <province-city-dialog v-if="areaShow" @get-operation-area="getProvinceCity"></province-city-dialog>
      <operaction-area-dialog v-if="operationShow" @get-operation-area="getOperationArea"></operaction-area-dialog>
    </el-form>
  </div>
</template>

<script>
import {baseUrl} from "@/plugins/api";
import upload from "@/components/admin/upload.vue";
import provinceCityDialog from "@/components/admin/provinceCityDialog";
import operactionAreaDialog from "@/components/admin/operactionAreaDialog";

export default {
  components: {
    upload,
    provinceCityDialog,
    operactionAreaDialog
  },
  data() {
    return {
      infoDetail: {
        legalerIdCardImageList: [],
        shopImageList: [],
        // shopHeadImage:[],
        businessLicenseImageList: [],
        businessCertificateList: [],
      },
      query: {},
      merchantType: [
        {value: 1, name: '个体户'},
        {value: 2, name: '企业商户'},
        {value: 3, name: '小微'},
      ],
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
      showPic: true,
      lock: false,
      span: 6,
      actionUploadUrl: '',
      upload: {
        title_card1: "身份证人像面",
        title_card2: "",
        title_card3: "",
        param: {fileType: "identity"},
        yyzz: {fileType: "business"},
        office: {fileType: "office"},
      },
      areaShow: false,
      operationShow: false,
      typeList: [],
    };
  },
  methods: {
    success() {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const postData = {
              state: 1,
              rejectReason: "",
              id: this.infoDetail.id,
            };
            this.post(this.$api.shopApplyApprove,postData);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    back() {
      this.$prompt("不通过理由", "商家门店审核", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({value}) => {
        const postData = {
          state: 2,
          rejectReason: value,
          id: this.infoDetail.id,
        };
        this.post(this.$api.shopApplyApprove, postData);
      });
    },
    update() {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.post(this.$api.updApplyMerchantInfo, this.infoDetail);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    infoData() {
      this.$get(
          this.$api.shopApplyInfo + "/" + this.$route.query.id,
          this.whale
      ).then((res) => {
        this.infoDetail = res.data;
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$router.go(-1);
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    incoming() {
      this.$get(this.$api.incoming + "/" + this.infoDetail.id + "/0").then(
          (res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "成功!",
              });
              this.$router.go(-1);
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          }
      );
    },
    getUploadFile(val) {
      console.log(val.field);
      this.infoDetail.merchantImages.forEach((rs) => {
        if (rs.imageType === val.field) {
          var data = {
            "id": rs.id,
            "imageType": rs.imageType,
            "imagePath": val.url,
          };
          this.$post(this.$api.merchantImageUpdate, data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: "失败!",
              });
            }
          });
        }
      });

    },
    getProvinceCity(data) {
      this.areaShow = false;
      console.log(data.provinceTxt, data.codeTxt);
      if (!data) return false;
      this.infoDetail.bankOpenArea = data.provinceTxt + ' ' + data.codeTxt;
    },
    getOperationArea(data) {
      this.operationShow = false;
      console.log(data);
      const val = data["code"];
      const name = data["name"];
      if (val && val.length > 0 && Array.isArray(val)) {
        const end = val.slice(-1);
        this.infoDetail.shopAreaCode = end[0];
        this.infoDetail.shopArea = name.join("");
      }
    },
    getTypeList() {

      this.$get(this.$api.shopCatgory).then((res) => {
        if (res.code === 1) {
          console.log(res.data.records);
          this.typeList = res.data.records;
        }
      });
    },
    getCategory(val){
      console.log(val);
    },
  },
  created() {
    this.query = this.$route.query;
    this.actionUploadUrl = baseUrl + "system/file/upload";
    this.infoData();
    this.getTypeList();
  },
};
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.layadmin-contact-box {
  width: 300px;
  display: inline-block;
  border: 1px solid #e7eaec;
  padding: 40px 0 40px 0;
}

.layadmin-text-center {
  text-align: center;
}

.layadmin-text-center .el-image {
  max-width: 40px;
  border-radius: 50%;
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.el-card {
  text-align: center;
}

.header-form {
  height: 40px;
  padding: 10px 0 10px 0;
}

.sub-title {
  font-size: 14px;
  color: #606266;
}

.images {
  display: block;
  margin-bottom: 40px;
}
</style>
